import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image-alt'
import loadable from '@loadable/component'

import { useSanityImageShape } from '../../helpers/useSanityImageShape'

const Button = loadable(() => import('../Button'))

const CardLink = ({ content, className }) => {
  let { link } = content

  if (link) {
    if (!link.endsWith('/')) {
      link = `${link}/`
    }
  }

  const featuredImageShaped = useSanityImageShape(content.bgImg) // was featuredImage

  return (
    <Link
      to={link}
      className={`bg-white rounded-lg overflow-hidden shadow-sm transition-shadow duration-300 transform hover:shadow-md relative h-350px md:h-390px ${className}`}
    >
      <div className="absolute inset-0 bg-black">
        <SanityImage
          {...featuredImageShaped}
          className="object-cover w-full h-full opacity-75"
          alt={content.title}
          width={650}
          height={390}
        />
      </div>
      <div className="absolute inset-0 z-20 flex flex-col items-start justify-end p-8">
        <h2 className="text-gray-200 text-24 md:text-30 tracking-subtitle font-bold mb-3">
          {content.title}
        </h2>
        <div className="truncate-last-line overflow-hidden break-normal h-55px md:h-67px leading-snug mb-4 text-14 md:text-16 text-gray-200">
          {/* <p>{content.description[0].children[0].text}</p> */}
        </div>
        <Button colour="white" psuedo>
          {content.buttonText}
        </Button>
      </div>
    </Link>
  )
}

CardLink.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
}

CardLink.defaultProps = {
  content: {},
  className: '',
}

export default CardLink
